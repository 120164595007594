<template>
  <sheet dense depressed light outlined>
    <v-row>
      <v-col cols="12" md="5" xl="4">
        <invoice-discounts v-if="showDiscounts" />
      </v-col>

      <v-col cols="12" md="6" offset-md="1" offset-xl="4" xl="4">
        <v-list color="transparent" dense tile>
          <!-- SUBTOTAL WITHOUT DISCOUNTS -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle v-text="$t('subtotal')" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                class="text-right"
                v-text="sSubtotalWithoutDiscounts"
              />
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item v-if="fDiscounts">
            <v-list-item-content>
              <v-list-item-subtitle v-text="$t('discounts')" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="text-right" v-text="sDiscounts" />
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <template v-for="obTax in arTaxes">
            <v-list-item v-if="obTax.total !== 0" :key="`tax-${obTax.id}`">
              <!-- <v-list-item-avatar v-text="currency.symbol" v-if="currency" /> -->
              <v-list-item-content>
                <v-list-item-subtitle v-text="obTax.name" />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title v-text="getCurrencyFormat(obTax.total)" />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t('tax.title.value')" />
              </v-list-item-content>
              <v-list-item-content>
                <div class="text-caption" v-text="`${obTax.percent}%`" />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title
                  class="text-right"
                  v-text="getCurrencyFormat(obTax.amount)"
                />
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider />

          <!-- ROUNDED LINE -->
          <template v-if="rounded">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-text="$t('amount.rounded')" />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title
                  class="text-right"
                  v-text="getCurrencyFormat(sRoundValue)"
                />
              </v-list-item-content>
            </v-list-item>

            <v-divider />
          </template>

          <v-list-item>
            <!-- <v-list-item-avatar v-text="currency.symbol" v-if="currency" /> -->
            <v-list-item-content>
              <v-list-item-subtitle v-text="$t('amount.total')" />
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold text-right">
                <animated-number
                  :complete="onAnimationComplete"
                  :formatValue="getCurrencyFormat"
                  :value="sTotalRounded"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <invoice-payment-methods
            v-if="showPaymentMethods"
            v-model="obInvoice"
          />
        </v-list>
      </v-col>
    </v-row>
  </sheet>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";

import AnimatedNumber from "animated-number-vue";
import InvoicePaymentMethods from "@/modules/invoices/components/paymentmethod/InvoicePaymentMethods.vue";
import InvoiceDiscounts from "@/modules/invoices/components/discounts/InvoiceDiscounts.vue";
import {
  InvoiceMovementType,
  InvoicePosition,
  InvoiceType,
} from "@planetadeleste/vue-mc-gw";
import { EventBus } from "@/services/event-bus";
import { debounce, forEach } from "lodash";
import hash from "object-hash";

@Component({
  components: { InvoicePaymentMethods, InvoiceDiscounts, AnimatedNumber },
})
export default class Totals extends Mixins(InvoiceMixin) {
  get showDiscounts() {
    return (
      this.sTypeCode !== InvoiceType.CODE_ERESGUARDO &&
      this.iMovementType !== InvoiceMovementType.CODE_DEBT &&
      this.iMovementType !== 15
    );
  }

  get showPaymentMethods() {
    return !!this.iMovementType && this.fTotalRounded !== 0;
    // return (
    //   !this.iMovementType ||
    //   ![InvoiceMovementType.CODE_DEBT].includes(this.iMovementType)
    // );
  }

  @Watch("positions")
  onWatchPositions(
    arNewPositions?: InvoicePosition[],
    arOldPositions?: InvoicePosition[]
  ) {
    if (arNewPositions?.length && arOldPositions?.length) {
      const arOldHashes: string[] = [];
      forEach(arOldPositions, (obItem) => {
        arOldHashes.push(hash(obItem.attributes));
      });

      const arHashes: string[] = [];
      forEach(arNewPositions, (obItem) => {
        const sHash = hash(obItem.attributes);
        if (arOldHashes.includes(sHash)) {
          return;
        }
        arHashes.push(sHash);
      });

      if (!arHashes.length) {
        return;
      }
    }

    this.onSetTaxAmountFromPositionsDebounced();
  }

  @Watch("discounts")
  onWatchDiscounts() {
    this.onSetTaxAmountFromPositionsDebounced();
  }

  onAnimationComplete() {
    EventBus.emit("invoice.update.paymentmethods");
  }

  created() {
    this.onSetTaxAmountFromPositionsDebounced = debounce(
      this.onSetTaxAmountFromPositions,
      500,
      { maxWait: 1500 }
    );
    EventBus.on(
      "invoice.apply.discounts",
      this.onSetTaxAmountFromPositionsDebounced
    );
  }

  beforeDestroy() {
    EventBus.off("invoice.apply.discounts");
  }
}
</script>
